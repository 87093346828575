import AdminCommunicationTemplateApi from "@/generatedapiclients/src/api/AdminCommunicationTemplateApi.js";

export default class AdminCommunicationTemplateService {
  constructor(tenantId) {
    this._tenantId = tenantId;
    this._adminCommunicationTemplateApi = new AdminCommunicationTemplateApi();
  }

  async getTemplates() {
    const response = await this._adminCommunicationTemplateApi.v1TenantTenantIdAdminAdminCommunicationTemplateCommunicationtemplatesGet(
      this._tenantId
    );
    return response;
  }
  async getTemplate(communicationTemplateId) {
    const response = await this._adminCommunicationTemplateApi.v1TenantTenantIdAdminAdminCommunicationTemplateCommunicationTemplateIdGet(
      communicationTemplateId,
      this._tenantId
    );
    return response;
  }
  async saveTemplate(communicationTemplate) {
    if (!communicationTemplate.id) {
      const response = await this._adminCommunicationTemplateApi.v1TenantTenantIdAdminAdminCommunicationTemplateCommunicationtemplatePost(
        this._tenantId,
        {
          communicationTemplate
        }
      );
      return response;
    }

    const response = await this._adminCommunicationTemplateApi.v1TenantTenantIdAdminAdminCommunicationTemplateCommunicationtemplatePut(
      this._tenantId,
      {
        communicationTemplate
      }
    );
    return response;
  }
  async deleteTemplate(communicationTemplateId) {
    const response = await this._adminCommunicationTemplateApi.v1TenantTenantIdAdminAdminCommunicationTemplateCommunicationTemplateIdDelete(
      communicationTemplateId,
      this._tenantId
    );
    return response;
  }
}
