/**
 * Reservations API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 *
 */


import ApiClient from "../ApiClient";
import CommunicationTemplate from '../model/CommunicationTemplate';
import CommunicationTemplateIEnumerableResponse from '../model/CommunicationTemplateIEnumerableResponse';
import CommunicationTemplateResponse from '../model/CommunicationTemplateResponse';
import Response from '../model/Response';

/**
* AdminCommunicationTemplate service.
* @module api/AdminCommunicationTemplateApi
* @version v1
*/
export default class AdminCommunicationTemplateApi {

    /**
    * Constructs a new AdminCommunicationTemplateApi. 
    * @alias module:api/AdminCommunicationTemplateApi
    * @class
    * @param {module:ApiClient} [apiClient] Optional API client implementation to use,
    * default to {@link module:ApiClient#instance} if unspecified.
    */
    constructor(apiClient) {
        this.apiClient = apiClient || ApiClient.instance;
    }



    /**
     * @param {Number} communicationTemplateId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Response} and HTTP response
     */
    v1TenantTenantIdAdminAdminCommunicationTemplateCommunicationTemplateIdDeleteWithHttpInfo(communicationTemplateId, tenantId) {
      let postBody = null;
      // verify the required parameter 'communicationTemplateId' is set
      if (communicationTemplateId === undefined || communicationTemplateId === null) {
        throw new Error("Missing the required parameter 'communicationTemplateId' when calling v1TenantTenantIdAdminAdminCommunicationTemplateCommunicationTemplateIdDelete");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminCommunicationTemplateCommunicationTemplateIdDelete");
      }

      let pathParams = {
        'communicationTemplateId': communicationTemplateId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Response;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminCommunicationTemplate/{communicationTemplateId}', 'DELETE',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} communicationTemplateId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Response}
     */
    v1TenantTenantIdAdminAdminCommunicationTemplateCommunicationTemplateIdDelete(communicationTemplateId, tenantId) {
      return this.v1TenantTenantIdAdminAdminCommunicationTemplateCommunicationTemplateIdDeleteWithHttpInfo(communicationTemplateId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {Number} communicationTemplateId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CommunicationTemplateResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminCommunicationTemplateCommunicationTemplateIdGetWithHttpInfo(communicationTemplateId, tenantId) {
      let postBody = null;
      // verify the required parameter 'communicationTemplateId' is set
      if (communicationTemplateId === undefined || communicationTemplateId === null) {
        throw new Error("Missing the required parameter 'communicationTemplateId' when calling v1TenantTenantIdAdminAdminCommunicationTemplateCommunicationTemplateIdGet");
      }
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminCommunicationTemplateCommunicationTemplateIdGet");
      }

      let pathParams = {
        'communicationTemplateId': communicationTemplateId,
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CommunicationTemplateResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminCommunicationTemplate/{communicationTemplateId}', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {Number} communicationTemplateId 
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CommunicationTemplateResponse}
     */
    v1TenantTenantIdAdminAdminCommunicationTemplateCommunicationTemplateIdGet(communicationTemplateId, tenantId) {
      return this.v1TenantTenantIdAdminAdminCommunicationTemplateCommunicationTemplateIdGetWithHttpInfo(communicationTemplateId, tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/CommunicationTemplate} opts.communicationTemplate 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Response} and HTTP response
     */
    v1TenantTenantIdAdminAdminCommunicationTemplateCommunicationtemplatePostWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['communicationTemplate'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminCommunicationTemplateCommunicationtemplatePost");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Response;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminCommunicationTemplate/communicationtemplate', 'POST',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/CommunicationTemplate} opts.communicationTemplate 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Response}
     */
    v1TenantTenantIdAdminAdminCommunicationTemplateCommunicationtemplatePost(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminCommunicationTemplateCommunicationtemplatePostWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/CommunicationTemplate} opts.communicationTemplate 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/Response} and HTTP response
     */
    v1TenantTenantIdAdminAdminCommunicationTemplateCommunicationtemplatePutWithHttpInfo(tenantId, opts) {
      opts = opts || {};
      let postBody = opts['communicationTemplate'];
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminCommunicationTemplateCommunicationtemplatePut");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = ['application/json-patch+json', 'application/json', 'text/json', 'application/_*+json'];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = Response;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminCommunicationTemplate/communicationtemplate', 'PUT',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @param {Object} opts Optional parameters
     * @param {module:model/CommunicationTemplate} opts.communicationTemplate 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/Response}
     */
    v1TenantTenantIdAdminAdminCommunicationTemplateCommunicationtemplatePut(tenantId, opts) {
      return this.v1TenantTenantIdAdminAdminCommunicationTemplateCommunicationtemplatePutWithHttpInfo(tenantId, opts)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with an object containing data of type {@link module:model/CommunicationTemplateIEnumerableResponse} and HTTP response
     */
    v1TenantTenantIdAdminAdminCommunicationTemplateCommunicationtemplatesGetWithHttpInfo(tenantId) {
      let postBody = null;
      // verify the required parameter 'tenantId' is set
      if (tenantId === undefined || tenantId === null) {
        throw new Error("Missing the required parameter 'tenantId' when calling v1TenantTenantIdAdminAdminCommunicationTemplateCommunicationtemplatesGet");
      }

      let pathParams = {
        'tenantId': tenantId
      };
      let queryParams = {
      };
      let headerParams = {
      };
      let formParams = {
      };

      let authNames = ['bearer'];
      let contentTypes = [];
      let accepts = ['text/plain', 'application/json', 'text/json'];
      let returnType = CommunicationTemplateIEnumerableResponse;
      return this.apiClient.callApi(
        '/v1/tenant/{tenantId}/admin/AdminCommunicationTemplate/communicationtemplates', 'GET',
        pathParams, queryParams, headerParams, formParams, postBody,
        authNames, contentTypes, accepts, returnType, null
      );
    }

    /**
     * @param {String} tenantId 
     * @return {Promise} a {@link https://www.promisejs.org/|Promise}, with data of type {@link module:model/CommunicationTemplateIEnumerableResponse}
     */
    v1TenantTenantIdAdminAdminCommunicationTemplateCommunicationtemplatesGet(tenantId) {
      return this.v1TenantTenantIdAdminAdminCommunicationTemplateCommunicationtemplatesGetWithHttpInfo(tenantId)
        .then(function(response_and_data) {
          return response_and_data.data;
        });
    }


}
