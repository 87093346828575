<template>
  <div>
    <b-modal id="previewModal" ok-only size="xl">
      <template #modal-title>
        <h1>{{ title }}</h1>
      </template>
      <div v-html="content"></div>
    </b-modal>
  </div>
</template>

<script>
export default {
  name: "AdminHtmlPreviewModal",

  props: {
    title: { type: String },
    content: { type: String }
  },
  methods: {
    show() {
      this.$bvModal.show("previewModal");
    }
  }
};
</script>

<style scoped></style>
